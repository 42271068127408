package be.flashapps.portfolio.styles

import be.flashapps.portfolio.models.Theme
import com.varabyte.kobweb.compose.css.CSSTransition
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.anyLink
import com.varabyte.kobweb.silk.components.style.hover
import org.jetbrains.compose.web.ExperimentalComposeWebApi
import org.jetbrains.compose.web.css.*

val ServiceCardStyle by ComponentStyle {
    base {
        Modifier
            .border(
                width = 2.px,
                style = LineStyle.Solid,
                color = Theme.LighterGray.rgb
            )
            .backgroundColor(Colors.White)
            .transition(CSSTransition(property = "border", duration = 200.ms))
            .transition(CSSTransition(property = "background", duration = 200.ms))
    }
    hover {
        Modifier
            .backgroundColor(Theme.Primary.rgb)
            .border(
                width = 2.px,
                style = LineStyle.Solid,
                color = Theme.Primary.rgb
            )
    }

    //targeting child element with id iconBox
    cssRule(" > #iconBox") {
        Modifier.backgroundColor(Colors.Transparent).transition(CSSTransition("background", duration = 200.ms))
    }

    cssRule(":hover > #iconBox") {
        Modifier.backgroundColor(Colors.White)
    }

    //targeting child element with type P (paragraph)
    cssRule(" > p") {
        Modifier.color(Theme.Secondary.rgb).transition(CSSTransition("color", duration = 200.ms))
    }

    cssRule(":hover > p") {
        Modifier.color(Colors.White)
    }
}