package be.flashapps.portfolio.styles

import com.varabyte.kobweb.compose.css.CSSTransition
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.hover
import org.jetbrains.compose.web.ExperimentalComposeWebApi
import org.jetbrains.compose.web.css.*


@OptIn(ExperimentalComposeWebApi::class)
val BackToTopButtonStyle by ComponentStyle {
    base {
        Modifier
            .rotate(a = 180.deg)
            .transition(CSSTransition("rotate", duration = 300.ms))
        //use .ALL because we are animating multiple properties
    }

    hover {
        Modifier.rotate(a = 0.deg)
    }
}
